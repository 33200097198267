<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './NotaViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idnota
        item.title = item.titulo
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.titulo
      this.moreInfoRows = [
        { name: 'fnota', label: 'Fecha', filter: this.$options.filters.shortDateTime,},
        { name: 'nota_texto', label: 'Nota' },
        { name: 'tnota_descripcion', label: 'Tipo de nota' },
      ]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectNota(
          this, this.routeParams.idnota
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
  },
}
</script>
